import React from 'react';
import ReactDOM from 'react-dom';

const tooltipStyles = {
  tooltip: {
    position: 'fixed',
    zIndex: 9999,
    padding: '8px 12px',
    fontSize: '14px',
    color: 'white',
    backgroundColor: '#333',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    pointerEvents: 'auto',
    minWidth: '120px',
    textAlign: 'center'
  },
  arrow: {
    position: 'absolute',
    width: '12px',
    height: '12px',
    backgroundColor: '#333',
    transform: 'rotate(45deg)',
    bottom: '-6px',
    left: '50%',
    marginLeft: '-6px'
  },
  container: {
    display: 'inline-block'
  }
};

class ToolTippedV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      position: { x: 0, y: 0 }
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    if (!document.getElementById('tooltip-portal')) {
      const portalContainer = document.createElement('div');
      portalContainer.id = 'tooltip-portal';
      Object.assign(portalContainer.style, {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: '9999'
      });
      document.body.appendChild(portalContainer);
    }
  }

  updatePosition = () => {
    if (this.containerRef.current) {
      const rect = this.containerRef.current.getBoundingClientRect();
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      this.setState({
        position: {
          x: rect.left + (rect.width / 2) + scrollLeft,
          y: rect.top + scrollTop
        }
      });
    }
  };

  handleMouseEnter = () => {
    this.updatePosition();
    this.setState({ isVisible: true });
  };

  handleMouseLeave = () => {
    this.setState({ isVisible: false });
  };

  renderTooltip() {
    const { tooltip } = this.props;
    const { isVisible, position } = this.state;

    if (!isVisible) return null;

    const tooltipPosition = {
      ...tooltipStyles.tooltip,
      left: `${position.x}px`,
      top: `${position.y - 10}px`,
      transform: 'translate(-50%, -100%)'
    };

    return ReactDOM.createPortal(
      <div style={tooltipPosition}>
        {tooltip}
        <div style={tooltipStyles.arrow} />
      </div>,
      document.getElementById('tooltip-portal')
    );
  }

  render() {
    const { children } = this.props;

    return (
      <div 
        style={tooltipStyles.container}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={this.containerRef}
      >
        {children}
        {this.renderTooltip()}
      </div>
    );
  }
}

export default ToolTippedV2;