import React from 'react';

import Media from '../../model/Media';

import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";
import CheckBox from "../global/CheckBox";
import DataTable from "../global/DataTable";

function parseExcelFile(file, cb) {
    const reader = new FileReader();

    reader.onload = (e) => {
        try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            // Convert sheet to JSON with header:1 to use first row as headers
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            // Extract headers and rows
            const headers = jsonData[0];
            const rows = jsonData.slice(1);

            const result = rows.map(row => ({
                folder: row[headers.indexOf('Site Name')],
                name: row[headers.indexOf('Name')],
                url: row[headers.indexOf('Direct URL')],
                video_360: row[headers.indexOf('360 Video')] ? true : false,
            }));

            cb(null, result);
        } catch (error) {
            console.log(error);
            cb(error);
        }
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
}

export default class ImportMediaListModal extends React.Component {

    state = { opened: false, openModal: null, closeModal: null };

    componentDidMount() {
        window.openMediaListImportModal = this.openModal;
    }

    getOpen = (open) => {
        this.setState({ openModal: open });
    };

    openModal = (options = {}, close_cb = function () { }) => {
        let state = {
            opened: true,
            undismissible: options.undismissible,
            close_cb: close_cb,
            media_list: []
        };

        this.setState(state, this.state.openModal);
    };

    getClose = (close) => {
        this.setState({ closeModal: close });
    };

    afterOpen = () => {
        //$(".lean-overlay").last().addClass("alert-popup-overlay");
    };

    afterClose = () => {
        this.setState({ opened: false }, this.state.close_cb);
    };

    onValueChange = (value) => {
        this.setState({ value: value });
    };

    _fileInputRef;

    onFileInputClick = () => {
        this._fileInputRef.click()
    };

    onInputChange = () => {
        if (this._fileInputRef == null || this._fileInputRef.value === "")
            return;
        const files = this._fileInputRef.files;
        if (files.length > 0) {
            const file = files[0];
            parseExcelFile(file, (err, result) => {
                this._fileInputRef.value = "";
                if (err) {
                    alert('Error parsing file');
                    return;
                }
                this.setState({ media_list: result });
            });
        }
    };

    onSubmit = () => {
        Media.importMediaList(this.state.media_list, (err) => {
            this.state.closeModal();
            if(err){
                alert('Error importing media list');
            }
        });
    };

    renderMediaTable = () => {
        let headers = ['Folder', 'Name', 'URL', '360 Video'];
        let rows = this.state.media_list.map((media, index) => {
            return { data: [media.folder, media.name, media.url, media.video_360 ? 'Yes' : 'No'] };
        });
        return <DataTable headers={{ headers }} rows={rows} />;
    }

    render() {
        let disabled = false, content;
        if (this.state.opened) {
            content = <div id="media-list-import-content" className="reglisse">
                <h3 style={{ margin: 0 }}>{"Media Import"}</h3>
                <div>
                    <Button color="green" text={"Import"}
                        style={{ marginLeft: '20px' }}
                        onClick={this.onFileInputClick} />
                    <input ref={c => this._fileInputRef = c}
                        type="file" onChange={this.onInputChange} style={{ display: "none" }} />
                </div>
                {this.renderMediaTable()}
                <div className="modal-footer">
                    <Button text={'Submit'} className='t18-btn' onClick={this.onSubmit} disabled={disabled} style={{ fontSize: "20px" }} />
                    <Button text={"Cancel"} className='t18-btn black' onClick={this.state.closeModal} large={true}
                        style={{ fontSize: "20px" }} />
                </div>
            </div>;
        }
        return (
            <Modal id="media-list-import-modal"
                content={content}
                getOpen={this.getOpen}
                getClose={this.getClose}
                afterOpen={this.afterOpen}
                afterClose={this.afterClose}
                undismissible={this.state.undismissible}
            />
        )
    }
};
