
import generic from "./generic";
import aws_s3 from "../aws_s3";
import API from "../api";
import config from "../config";
import User from "./user";

const Media = {

    loadOwnedMedias: (cb) => {
      generic.loadOwnedEntities('media', cb);
    },

    refreshMedia : (media_id, cb = () => {}, options) => {
        generic.refreshEntity('media', media_id, cb, options);
    },

    userCreateMedia: (data, cb) => {
      generic.createEntity('media', data, cb);
    },

    userUpdateMedia: (id, data, cb) => {
      generic.updateEntity('media', id, data, cb);
    },

    getMedia: (id) => {
      return generic.getEntity('media', id);
    },

    getOwnedMedias: () => {
      return generic.getOwnedEntities('media');
    },

    uploadMedia: (type, file, metadata, path, complete_cb, update_cb = () => {}) => {
        let filename = file.name, format;
        let upload_data = {type: type, filename: filename, metadata: metadata, path: path};
        if(type === 'picture'){
            format = getPictureFormatFromMetadata(metadata);
            upload_data.format = format;
        }
        API.mediaUploadRequest(upload_data, (data) => {
            let media_id = data.id, key = media_id;
            if(type === 'picture'){
                key += '_' + format.replace('r', '');
            }
            let reader = new FileReader();
            reader.onload = function() {
                let arrayBuffer = this.result;
                aws_s3.uploadFile(key, arrayBuffer, update_cb, (err, data) => {
                    if(err){
                        console.log(err);
                        return complete_cb(err);
                    }else{
                        API.mediaUploadCompleteRequest(media_id, () => {
                            Media.refreshMediaUntilProcessingDone(media_id);
                            return complete_cb(null, media_id);
                        }, (err) => {
                            return complete_cb(err);
                        })
                    }
                });
            }
            reader.readAsArrayBuffer(file);
        }, (err) => {
            return complete_cb(err);
        })
    },

    refreshMediaUntilProcessingDone: (media_id, cb = () => {}) => {
        Media.refreshMedia(media_id, (err, media) => {
            if(err){
                return cb(err);
            }
            if(media.processing){
                setTimeout(() => {
                    Media.refreshMediaUntilProcessingDone(media_id, cb);
                }, 10000);
            }else{
                return cb(null, media);
            }
        }, {no_loading: true})
    },

    getMediaDisplayUrl: (media_id) => {
        let media = Media.getMedia(media_id);
        if(!media) return;
        let url;
        if(media.type === 'picture'){
            url = media.url;
        }else{
            if(media.preview_url){
                url = media.preview_url;
            }else{
                let url_map = media.url_map;
                if(url_map){
                    for(let format of config.video_formats){
                        if(url_map[format]){
                            url = url_map[format];
                            break;
                        }
                    }
                }
            }
            if(!url && media.url){
                url = media.url;
            }
        }
        return url;
    },

    importMedia: (data, cb) => {
        let createMedia = (path) => {
            let media_data = {
                path: path,
                type: data.video_360 ? 'video_360' : 'video',
                name: data.name,
                tags: [],
                access: { type: 'free' },
                url_map: { 'r1080p': data.url }
            };
            Media.userCreateMedia(media_data, (err, media_id) => {
                if(err){
                    return cb(err);
                }
                return cb(null, media_id);
            });
        }
        let existing_folder = User.getFolderFromNameAndLevel(data.folder, 1);
        if(!existing_folder){
            User.addMediaFolder({parent_path: '/', name: data.folder}, (err) => {
                if(err){
                    console.log(err);
                    window.alertPopup('Error', 'An error has occurred while creating new folder.');
                }else{
                    User.refreshUser(() => {
                        existing_folder = User.getFolderFromNameAndLevel(data.folder, 1);
                        createMedia(existing_folder.path);
                    });
                }
            });
        }else{
            createMedia(existing_folder.path);
        }
    },

    importMediaList: (media_list, cb) => {
        if(media_list.length === 0){
            return cb();
        }
        let current_index = 0;
        let process_next = () => {
            let media_data = media_list[current_index];
            Media.importMedia(media_data, (err) => {
                if(err){
                    return cb(err);
                }
                current_index++;
                if(current_index < media_list.length){
                    process_next();
                }else{
                    return cb();
                }
            });
        }
        process_next();
    },

    // Events

    onMediaUpdateSubscribe: (cb) => {
      generic.addEntityListener('media', cb);
    }

};

function getPictureFormatFromMetadata(metadata){
    let width = metadata.width || 0,
        height = metadata.height || 0,
        max_size = Math.max(width, height),
        result;
    for(let format of config.picture_formats){
        if(max_size <= config.picture_format_sizes[format]){
            result = format;
            break;
        }
    }
    return result;
}

window.Media = Media;

export default Media;